import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import {
  Layout,
  SEO,
  Hero,
  Map,
  Contact,
  TheServices,
  OurClients,
  SuitsOverlay,
} from '../../components';
import { useServices } from '../../data';

function ServicesPage() {
  const services = useServices();

  const {
    file,
    site: { siteMetadata },
  } = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "services-hero.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO title="Bases for Poker and Electronic Gaming Machines" />
      <Hero
        image={file.childImageSharp.fluid}
        heading="Gaming Machine Services"
        copy={`${siteMetadata.title} are renowned for the highest quality manufacture, design, and installation of Gaming Machine Bases for Clubs, Hotels and other hospitality venues around Australia. We tailor designs to your vision and can fit-out entire gaming rooms to suit both décor and regulatory requirements. We ensure your venue gains a world-class result, every time.`}
      />
      <SuitsOverlay />
      <TheServices items={services} />
      <OurClients />
      <Contact />
      <Map />
    </Layout>
  );
}

export default ServicesPage;
